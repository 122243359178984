import React from "react";
import "./Home.scss";
import { FaFacebookF, FaLinkedinIn } from "react-icons/fa";
import { GrInstagram } from "react-icons/gr";
import { BsFillEnvelopeFill } from "react-icons/bs";

function FooterComponent() {
  return (
    <div className="row footer-tab">
      <div className="col-md-2 col-sm-6">
        <p>Initiated By:</p>
        <div className="footer-logo">
          <a href="https://renergo.in/" target="_blanks">
            <img src="images/renergo logo-01.png" alt="rener" />
          </a>
        </div>
      </div>
      <div className="col-md-2 col-sm-6">
        <p>Supported By:</p>
        <div className="footer-logo">
          <a href="https://onecupad.com/" target="_blanks">
            <img src="images/Onecup logo-01.png" alt="onceup" />
          </a>
        </div>
      </div>
      <div className="col-md-4 col-sm-12 mt-15">
        <p>Exclusive Resilience Partner:</p>
        <div className="footer-logo">
          <a href="https://ghtonwheels.com" target="_blanks">
            <img src="images/silence.jpg" alt="silence" />
          </a>
        </div>
      </div>

      <div className="col-md-4 footer-content">
        <p className="v-h-d">Contact us</p>
        <div className="social">
          <a href="https://facebook.com/letsavelife.org" target="_blanks">
            <div className="facebook">
              <FaFacebookF style={{ color: "#3b5998" }} />
            </div>
          </a>
          <div className="facebook">
            <FaLinkedinIn style={{ color: "#0e76a8" }} />
          </div>
          <div className="facebook">
            <GrInstagram style={{ color: "#bc2a8b" }} />
          </div>
          <a href="mailto:letsavelife.org@gmail.com" target="_blanks">
            <div className="facebook">
              <BsFillEnvelopeFill style={{ color: "rgb(187 0 27 / 61%)" }} />
            </div>
          </a>
        </div>
      </div>
    </div>
  );
}

export default FooterComponent;
