import react from "react";
import "./Info.scss";

class Info extends react.Component {
  render() {
    return (
      <div className="work-info ">
        <div class="section-gap">
          <div class="row">
            <div class="col-md-6">
              <div className="container work-list">
                <h1 class="main-title">HOW IT WORKS</h1>

                <div className="d-flex ml-25 mb-30">
                  <h1 class="circle light">1</h1>
                  <div class="info-content">
                    <p className="content">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do
                    </p>
                  </div>
                </div>

                <div className="d-flex ml-25 mb-30">
                  <h1 class="circle">2</h1>
                  <div class="info-content">
                    <p className="content">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do
                    </p>
                  </div>
                </div>

                <div className="d-flex ml-25 mb-30">
                  <h1 class="circle dark">3</h1>
                  <div class="info-content">
                    <p className="content">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="safe">
                <img src="images/safe.png" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Info;
