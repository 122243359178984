import axios from "axios";
import React, { useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import Datas from "../Home/datas";

// import Recaptcha from "react-recaptcha";

function OxygenPatient({
  selectedCountries,
  districts,
  changeCountry,
}) {

  const countries = Datas;
  const [isverified, setIsverified] = useState(false);
  const [isLoading, setIsloading] = useState(false);


  const history = useHistory();
  const setCountries = (e) => {
    formik.setValues({
      ...formik.values,
      country:e.target.value
    })
    changeCountry(e);
  };

  const captchaClick = () => {
    setIsverified(true)
  }
  const nameRegex = /^[a-zA-Z ]*$/;

  const ValidationSchema = Yup.object().shape({
    name: Yup.string()
      .matches(nameRegex,"Should contain only alphabets")
      .min(0, "Too Short!")
      .max(50, "Too Long!")
      .required("Full Name is Required"),
    email: Yup.string().email("Invalid email"), 
    district: Yup.string()
      .required("State is required"),
    mobile: Yup.number()
      .min(1000000000, "too short")
      .max(13000000000, "too long")
      .required("Mobile Number is required"),
    contactPersonMobile: Yup.number()
      .min(1000000000, "too short")
      .max(13000000000, "too long")
      .required("Phone Number is required"),
    citizenship: Yup.string().required("Document Number is required"),
    contactPerson: Yup.string().required("Contact Person Name is required"),
    country: Yup.string().required("Country is required"),
    type: Yup.string().required("Select any one")
  })

  const formik = useFormik({
    initialValues: {
      country:'',
      name:'',
      email: '',
      mobile:'',
      district:'',
      citizenship: '',
      contactPersonMobile: '',
      contactPerson:'',
      type:'' 
    },
    validationSchema:ValidationSchema,
    onSubmit: async(values) => {
      setIsloading(true)
      await axios
      .post("https://admin.letsavelife.com/api/oxygen/receiver", {
        country: values.country,
        relation_type: values.type,
        name: values.name,
        document_no: values.citizenship,
        contact_patient: values.mobile,
        contact_primary: values.contactPersonMobile,
        name_primary: values.contactPerson,
        email: values.email,
        district:values.district,
      })
      .then((res) => {
        console.log("done");
        if (res.status === 200) {
          toast.success("form submitted successfully", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        history.push("/main");
        setIsloading(false)
      });
    }})  
  return (
    <form onSubmit={formik.handleSubmit}>
    <div class="custom-form oxygen-patient">
      <ToastContainer
        position="top-left"
        hideProgressBar={false}
        autoClose={4000}
        newestOnTop={true}
        closeOnClick={false}
        draggable={false}
        rtl={false}
      />
      <div class="tab-content">
        <div class="row justify-content-md-center">
          <div className="col-md-9">
            <div className="section-card">
              <div className="row">
                <div className="col-md-6 child">
                  <div className="input-content-block">
                    <label className="content" for="country">
                      Select your Country<i className="required">*</i>
                    </label>

                    <select
                      className="input-field"
                      name="country"
                      id="country"
                      value={formik.values.country}
                      onChange={(e) => setCountries(e)}
                    >
                      <option selected disabled value=""> -- Choose Country --</option>
                      {countries.map((x) => {
                        return <option>{x.name}</option>;
                      })}
                    </select>
                    {formik.errors.country ? <div style={{color:"red"}}><small>{formik.errors.country}</small></div> : null}

                  </div>
                </div>

                <div class="col-md-6 child">
                  <div className="input-content-block">
                    <label className="content">Patient Name <i className="required">*</i></label>
                    <input
                      className="input-field"
                      type="text"
                      placeholder="Enter Patient  name"
                      {...formik.getFieldProps("name")}
                    />
                    {formik.errors.name ? <div style={{color:"red"}}><small>{formik.errors.name}</small></div> : null}

                  </div>
                </div>
                <div class="col-md-6 child">
                  <div className="input-content-block">
                    <label className="content">
                      Patient Citizenship or Adhar Card Number<i className="required">*</i>
                    </label>
                    <input
                      className="input-field"
                      type="number"
                      min="0"
                      placeholder="Patient Citizenship or Adhar card number"
                      {...formik.getFieldProps("citizenship")}

                    />
                    {formik.errors.citizenship ? <div style={{color:"red"}}><small>{formik.errors.citizenship}</small></div> : null}

                  </div>
                </div>

                <div class="col-md-6 child">
                  <div className="input-content-block">
                    <label className="content">Mobile No.<i className="required">*</i></label>
                    <input
                      className="input-field"
                      type="number"
                      minLength="7"
                      min="1000000"
                      placeholder="Enter Patient Mobile Number"
                      {...formik.getFieldProps("mobile")}

                    />
                    {formik.errors.mobile ? <div style={{color:"red"}}><small>{formik.errors.mobile}</small></div> : null}

                  </div>
                </div>

                <div class="col-md-6 child">
                  <div className="input-content-block">
                    <label className="content">Conatct person's Name<i className="required">*</i></label>
                    <input
                      className="input-field"
                      type="text"
                      placeholder="enter Contact Person's Name"
                      {...formik.getFieldProps("contactPerson")}

                    />
                    {formik.errors.contactPerson ? <div style={{color:"red"}}><small>{formik.errors.contactPerson}</small></div> : null}

                  </div>
                </div>

                <div className="col-md-6 child">
                  <div className="input-content-block">
                    <label className="content">
                      Contact Person's Phone Number<i className="required">*</i>
                    </label>
                    <input
                      className="input-field"
                      type="number"
                      minLength="7"
                      min="1000000"
                      placeholder="enter Contact Person's Phone Number"
                      {...formik.getFieldProps("contactPersonMobile")}
                    />
                    {formik.errors.contactPersonMobile ? <div style={{color:"red"}}><small>{formik.errors.contactPersonMobile}</small></div> : null}

                  </div>
                </div>

                <div class="col-md-6 child">
                  <div className="input-content-block">
                    <label className="content">Email</label>
                    <input
                      className="input-field"
                      type="email"
                      placeholder="enter Your Email Address"
                      {...formik.getFieldProps("email")}

                    />
                    {formik.errors.email ? <div style={{color:"red"}}><small>{formik.errors.email}</small></div> : null}

                  </div>
                </div>

                <div className="col-md-6 child">
                  <div className="input-content-block">
                    <label className="content" for="district">
                      Select Your State<i className="required">*</i>
                    </label>
                    <select
                      className="input-field"
                      name="district"
                      id="district"
                      {...formik.getFieldProps("district")}

                    >

                    <option selected disabled value="">-- Choose State --</option>
                      {districts?.map((d, index) => {
                        return <option key={index}>{d}</option>;
                      })}
                    </select>
                      {formik.errors.district ? <div style={{color:"red"}}><small>{formik.errors.district}</small></div> : null}
                  </div>
                </div>
                <div className="col-md-6 child">
                  <div className="input-content-block">
                    <label className="content">
                      Are you a patient, relative or volunteer?<i className="required">*</i>
                    </label>

                    <div className="radio-block">
                      <div className="radio-content">
                        <input
                          type="radio"
                          id="patient"
                          name="type"
                          value="patient"
                          onChange={() => formik.setValues({
                            ...formik.values,
                            type:"Patient"
                          })}
                        />
                        <label className="content" for="patient">
                          Patient
                        </label>
                      </div>
                      <div className="radio-content">
                        <input
                          type="radio"
                          id="relative"
                          name="type"
                          value="relative"
                          onChange={() => formik.setValues({
                            ...formik.values,
                            type:"Relative"
                          })}
                        />
                        <label className="content" for="relative">
                          Relative
                        </label>
                      </div>
                      <div className="radio-content">
                        <input
                          type="radio"
                          id="volunteer"
                          name="type"
                          value="volunteer"
                          onChange={() => formik.setValues({
                            ...formik.values,
                            type:"volunteer"
                          })}
                        />
                        <label className="content" for="volunteer">
                          volunteer
                        </label>
                      </div>
                      {formik.errors.type ? <div style={{color:"red"}}><small>{formik.errors.type}</small></div> : null}

                    </div>
                  </div>
                </div>
              </div>
              <div className=" condition">
                <label class="small-content">
                  By submitting this form,you accept terms and conditions
                </label>
              </div>

              <div className="center" style={{ textAlign: "center" }}>
                {/* <Recaptcha
                sitekey="6LfUivQaAAAAALVG3yflLPxuh2Jn89Ks_zM5eIpN"
                render="explicit"
                verifyCallback={captchaClick}
              /> */}
              </div>
              <div class="text-center">
              {isLoading?
                <button class="btn btn-primary btn-add" disabled type="submit">
                  Submit
                </button>:
                <button class="btn btn-primary btn-add" type="submit">
                  Submit
                </button>
}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </form>
  );
}

export default OxygenPatient;
