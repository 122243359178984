import React, { useState } from "react";
import "./Form.scss";
import axios from "axios";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import Datas from "../Home/datas";

// import Recaptcha from "react-recaptcha";

function PlasmaPatient({ selectedCountries, districts, changeCountry }) {
  const [isverified, setIsverified] = useState(false);
  const [isLoading, setIsloading] = useState(false);

  const countries = Datas;
  const history = useHistory();

  const setCountries = (e) => {
    formik.setValues({
      ...formik.values,
      country: e.target.value,
    });
    changeCountry(e);
  };

  const captchaClick = () => {
    setIsverified(true);
  };

  const nameRegex = /^[a-zA-Z ]*$/;

  const ValidationSchema = Yup.object().shape({
    name: Yup.string()
      .matches(nameRegex, "Should contain only alphabets")
      .min(0, "Too Short!")
      .max(50, "Too Long!")
      .required("Patient Name is required"),
    email: Yup.string().email("Invalid email"),
    district: Yup.string().required("State is required"),
    mobile: Yup.number()
      .min(1000000000, "too short")
      .max(13000000000, "too long")
      .required("Mobile Number is required"),
    contactpersonmobile: Yup.number()
      .min(1000000000, "too short")
      .max(13000000000, "too long")
      .required("Contact Person Number is required"),
    age: Yup.number().required("Patient Age is required"),
    blood: Yup.string().required("Blood Group is required"),
    gender: Yup.string().required("Gender is required"),
    country: Yup.string().required("Country is required"),
    contactpersonname: Yup.string().required("Contact Person Name is required"),
    documentno: Yup.string().required("Document Number is required"),
    type: Yup.string().required("Select any one"),
  });

  const formik = useFormik({
    initialValues: {
      country: "",
      age: "",
      name: "",
      email: "",
      mobile: "",
      district: "",
      blood: "",
      gender: "",
      contactpersonname: "",
      contactpersonmobile: "",
      documentno: "",
      type: "",
    },
    validationSchema: ValidationSchema,
    onSubmit: async (values) => {
      setIsloading(true);
      await axios
        .post("https://admin.letsavelife.com/api/plasma/receiver", {
          age: values.age,
          patient_name: values.name,
          patient_contact: values.mobile,
          email: values.email,
          gender: values.gender,
          blood_type: values.blood,
          district: values.district,
          country: values.country,
          contact_person_name: values.contactpersonname,
          contact_person_number: values.contactpersonmobile,
          document_no: values.documentno,
          relation_type: values.type,
        })
        .then((res) => {
          console.log("done");
          if (res.status === 200) {
            toast.success("form submitted successfully", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            history.push("/main");
            setIsloading(false);
          }
        });
    },
  });

  return (
    <div class="custom-form plasma-patient">
      <ToastContainer
        position="top-left"
        hideProgressBar={false}
        autoClose={4000}
        newestOnTop={true}
        closeOnClick={false}
        draggable={false}
        rtl={false}
      />
      <form onSubmit={formik.handleSubmit}>
        <div class="tab-content">
          <div class="row justify-content-md-center">
            <div className="col-lg-9 col-md-12 ">
              <div className="section-card">
                <div className="row">
                  <div className="col-lg-6 col-md-6 child">
                    <div className="input-content-block">
                      <label className="content" for="country">
                        Select your Country<i className="required">*</i>
                      </label>
                      <select
                        className="input-field"
                        name="country"
                        id="country"
                        value={selectedCountries}
                        onChange={(e) => setCountries(e)}
                        // onChange={this.selectChange.apply.bind(this)}
                      >
                        <option selected disabled value="">
                          {" "}
                          -- Choose Country --
                        </option>
                        {countries?.map((x) => {
                          return <option>{x.name}</option>;
                        })}
                      </select>
                      {formik.errors.country ? (
                        <div style={{ color: "red" }}>
                          <small>{formik.errors.country}</small>
                        </div>
                      ) : null}
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-6 child">
                    <div className="input-content-block">
                      <label className="content" for="district">
                        select your state<i className="required">*</i>
                      </label>
                      <select
                        className="input-field"
                        name="District"
                        id="district"
                        {...formik.getFieldProps("district")}
                      >
                        <option selected disabled value="">
                          -- Choose state --
                        </option>
                        {districts?.map((d, index) => {
                          return <option key={index}>{d}</option>;
                        })}
                      </select>
                      {formik.errors.district ? (
                        <div style={{ color: "red" }}>
                          <small>{formik.errors.district}</small>
                        </div>
                      ) : null}
                    </div>
                  </div>

                  <div class="col-lg-6 col-md-6 child">
                    <div className="input-content-block">
                      <label className="content">
                        Patient Name<i className="required">*</i>
                      </label>
                      <input
                        className="input-field"
                        type="text"
                        {...formik.getFieldProps("name")}
                        placeholder="Enter Patient  name"
                      />
                      {formik.errors.name ? (
                        <div style={{ color: "red" }}>
                          <small>{formik.errors.name}</small>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6 child">
                    <div className="input-content-block">
                      <label className="content">
                        contact person's Name<i className="required">*</i>
                      </label>
                      <input
                        className="input-field"
                        type="text"
                        {...formik.getFieldProps("contactpersonname")}
                        placeholder="Enter contact person's  name"
                      />
                      {formik.errors.contactpersonname ? (
                        <div style={{ color: "red" }}>
                          <small>{formik.errors.contactpersonname}</small>
                        </div>
                      ) : null}
                    </div>
                  </div>

                  <div class="col-lg-6 col-md-6 child">
                    <div className="input-content-block">
                      <label className="content">
                        Patient Mobile No.<i className="required">*</i>
                      </label>
                      <input
                        className="input-field"
                        type="number"
                        minLength="7"
                        min="1000000"
                        {...formik.getFieldProps("mobile")}
                        placeholder="Enter Patient Mobile Number"
                      />
                      {formik.errors.mobile ? (
                        <div style={{ color: "red" }}>
                          <small>{formik.errors.mobile}</small>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6 child">
                    <div className="input-content-block">
                      <label className="content">
                        contact person's mobile No.<i className="required">*</i>
                      </label>
                      <input
                        className="input-field"
                        type="number"
                        minLength="7"
                        min="1000000"
                        {...formik.getFieldProps("contactpersonmobile")}
                        placeholder="Enter contact person's Mobile Number"
                      />
                      {formik.errors.contactpersonmobile ? (
                        <div style={{ color: "red" }}>
                          <small>{formik.errors.contactpersonmobile}</small>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6 child">
                    <div className="input-content-block">
                      <label className="content">
                        Patient Age<i className="required">*</i>
                      </label>
                      <input
                        className="input-field"
                        type="number"
                        placeholder="Enter  Patient's  Age"
                        min="0"
                        {...formik.getFieldProps("age")}
                      />
                      {formik.errors.age ? (
                        <div style={{ color: "red" }}>
                          <small>{formik.errors.age}</small>
                        </div>
                      ) : null}
                    </div>
                  </div>

                  <div class="col-lg-6 col-md-6 child">
                    <div className="input-content-block">
                      <label className="content">Email</label>
                      <input
                        className="input-field"
                        type="email"
                        {...formik.getFieldProps("email")}
                        placeholder="Enter your Email Address "
                      />
                      {formik.errors.email ? (
                        <div style={{ color: "red" }}>
                          <small>{formik.errors.email}</small>
                        </div>
                      ) : null}
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-6 child">
                    <div className="input-content-block">
                      <label className="content">
                        gender<i className="required">*</i>
                      </label>
                      <select
                        className="input-field"
                        name="gender"
                        id="gender"
                        {...formik.getFieldProps("gender")}
                      >
                        <option disabled selected value="">
                          --Select Gender--
                        </option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                        <option value="other">Other</option>
                      </select>
                      {formik.errors.gender ? (
                        <div style={{ color: "red" }}>
                          <small>{formik.errors.gender}</small>
                        </div>
                      ) : null}
                    </div>
                  </div>

                  <div class="col-lg-6 col-md-6 child">
                    <div className="input-content-block">
                      <label className="content" for="blood-grp">
                        Blood Group<i className="required">*</i>
                      </label>
                      <select
                        className="input-field"
                        name="blood-grp"
                        id="blood-grp"
                        {...formik.getFieldProps("blood")}
                      >
                        <option selected disabled value="">
                          Select blood Group
                        </option>
                        <option value="A+">A+</option>
                        <option value="A-">A-</option>
                        <option value="B+">B+</option>
                        <option value="B-">B-</option>
                        <option value="O+">O+</option>
                        <option value="O-">O-</option>
                        <option value="AB+">AB+</option>
                        <option value="AB-">AB-</option>
                      </select>
                      {formik.errors.blood ? (
                        <div style={{ color: "red" }}>
                          <small>{formik.errors.blood}</small>
                        </div>
                      ) : null}
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-6 child">
                    <div className="input-content-block">
                      <label className="content">
                        Are you a patient, relative or volunteer?
                        <i className="required">*</i>
                      </label>

                      <div className="radio-block">
                        <div className="radio-content">
                          <input
                            type="radio"
                            id="patient"
                            name="relation_type"
                            value="patient"
                            onChange={() =>
                              formik.setValues({
                                ...formik.values,
                                type: "Patient",
                              })
                            }
                          />
                          <label className="content" for="patient">
                            Patient
                          </label>
                        </div>
                        <div div className="radio-content">
                          <input
                            type="radio"
                            id="relative"
                            name="relation_type"
                            value="relative"
                            onChange={() =>
                              formik.setValues({
                                ...formik.values,
                                type: "Relative",
                              })
                            }
                          />
                          <label className="content" for="relative">
                            Relative
                          </label>
                        </div>
                        <div className="radio-content">
                          <input
                            type="radio"
                            id="volunteer"
                            name="relation_type"
                            value="volunteer"
                            onChange={() =>
                              formik.setValues({
                                ...formik.values,
                                type: "Volunteer",
                              })
                            }
                          />
                          <label className="content" for="volunteer">
                            volunteer
                          </label>
                        </div>
                        {formik.errors.type ? (
                          <div style={{ color: "red" }}>
                            <small>{formik.errors.type}</small>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 child">
                    <div className="input-content-block">
                      <label className="content" for="documentno">
                        Patient’s Citizenship/Aadhar Card Number?
                        <i className="required">*</i>
                      </label>
                      <input
                        id="documentno"
                        className="input-field"
                        type="number"
                        min="0"
                        placeholder="Enter your Citizenship/adhar Number"
                        {...formik.getFieldProps("documentno")}
                      />
                      {formik.errors.documentno ? (
                        <div style={{ color: "red" }}>
                          <small>{formik.errors.documentno}</small>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div>
                  <label class="small-content ">
                    By submitting this form,you accept terms and conditions
                  </label>
                </div>
                {/* <Recaptcha
                sitekey="6LfUivQaAAAAALVG3yflLPxuh2Jn89Ks_zM5eIpN"
                render="explicit"
                verifyCallback={captchaClick}
              /> */}
                <div class="text-center">
                  {isLoading ? (
                    <button
                      class="btn btn-primary btn-add"
                      disabled
                      type="submit"
                    >
                      Submit
                    </button>
                  ) : (
                    <button class="btn btn-primary btn-add" type="submit">
                      Submit
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default PlasmaPatient;
