import react from "react";
import "./EmergencyContacts.scss";

import "react-toastify/dist/ReactToastify.css";
import { Pagination } from "@material-ui/lab";

class EmergencyContact extends react.Component {
  state = {
    searchedIndia:'',
    searchedNepal:'',
    pageNumber: 1,
    activePagination: 1,
    country: [
      {
        countryName: "India",
        links: [
          { linksUrl: " https://www.covid19india.org/" },
          { linksUrl: "https://www.mohfw.gov.in/" },
          { linksUrl: "https://indianhelpline.com/" },
          { linksUrl: "https://www.mygov.in/covid-19/" },
          {
            linksUrl:
              "https://dshm.delhi.gov.in/mis/(S(q0y221fajygpjz4eqxu0xcru))/Private/frmFreeBedMonitoringReport.aspx",
          },
        ],
        contact: [
          {
            name: "Andaman & Nicobar",
            number: "03192-232102",
          },
          {
            name: "Andhra Pradesh",
            number: "0866-2410978",
          },
          {
            name: "Arunachal Pradesh",
            number: "9436055743",
          },
          {
            name: "Assam",
            number: "6913347770",
          },
          {
            name: "Bihar",
            number: "104",
          },
          {
            name: "Chandigarh",
            number: "9779558282",
          },
          {
            name: "Chhattisgarh",
            number: "077122-35091",
          },
          {
            name: "Dadra Nagar Haveli",
            number: "104",
          },
          {
            name: "Delhi",
            number: "011-22307145",
          },
          {
            name: "Goa",
            number: "104",
          },
          {
            name: "Gujarat",
            number: "104",
          },
          {
            name: "Haryana",
            number: "8558893911",
          },
          {
            name: "Himachal Pradesh",
            number: "104",
          },
          {
            name: "Jammu",
            number: "01912520982",
          },
          {
            name: "Jharkhand",
            number: "104",
          },
          {
            name: "Karnataka",
            number: "104",
          },
          {
            name: "Kashmir",
            number: "01942440283",
          },
          {
            name: "Kerala",
            number: "0471-2552056",
          },
        ],
      },
      {
        countryName: "Nepal",
        links: [
          {
            linksUrl:
              " https://covid19.mohp.gov.np/hospital/covid_hospital.html",
          },
          { linksUrl: "https://covid19.mohp.gov.np/" },
          { linksUrl: "https://mohp.gov.np/" },
          { linksUrl: "https://heoc.mohp.gov.np/" },
          {
            linksUrl:
              "https://www.nepalisansar.com/coronavirus/live-nepals-covid-19-helpline-and-hospital-contact-numbers/",
          },
        ],
        contact: [
          {
            name: "Kathmandu",
            number: "1133",
          },
          {
            name: "24 Hours",
            number: "1133",
          },
          {
            name: "06:00 AM - 10:00 PM",
            number: "1115",
          },
          {
            name: "08:00 AM - 08:00 PM ",
            number: "9851-255-834/9851-255-837",
          },
        ],
      },
    ],
  };

  paginationItemNumer = (e, number, index) => {
    debugger;
    this.setState({
      pageNumber: number,
    });
  };

  searchIndia = (array) =>{
    if(this.state.searchedIndia!==""){
      return(
        array.filter(item=>item.name.toLowerCase().includes(this.state.searchedIndia.toLowerCase())))
    }
    else{
      return array;
    }
  }
  searchNepal = (array) =>{
    if(this.state.searchedNepal!==""){
      return(
        array.filter(item=>item.name.toLowerCase().includes(this.state.searchedNepal.toLowerCase())))
    }
    else{
      return array;
    }
  }
  emergencyContact = () => {
    return (
      <div className="emergency-contact section-gap">
        <div className="container">
          <h2 className="main-title">Emergency Contact</h2>
          {this.state.country.map((item, index) => {
            return (
              <>
                <h2 className="title country ">{item.countryName}</h2>
                <div className="section-card" key={index}>
                  <div className="row">
                    <div className="col-md-7">
                      <div className="emergency-area">
                        <h2 className="title">State hepline Numbers</h2>
                        <div className="search-block">
                          <input
                            type="text"
                            className="search-field"
                            placeholder="Search State Here"
                            onChange={(e)=>{item.countryName==="India"?this.setState({
                              ...this.state,
                              searchedIndia:e.target.value
                            }):this.setState({...this.state,
                               searchedNepal:e.target.value
                          })}}
                          />
                          <i className="bx bx-search-alt-2"></i>
                        </div>
                        <div className="emergency-section">
                          <div className="row">
                            {item.countryName === "India" ? (
                              <>
                                {this.searchIndia([...item.contact])?.slice(
                                    (this.state.pageNumber - 1) * 6,
                                    this.state.pageNumber * 6
                                  )?.map((itemInfo, index) => {
                                    return (
                                      <div
                                        className="col-md-6 child"
                                        key={index}
                                      >
                                        <div className="emergency-block">
                                          <p className="emergency-title">
                                            {itemInfo.name}
                                          </p>
                                          <p className="emergency-number">
                                            {itemInfo.number}
                                          </p>
                                        </div>
                                      </div>
                                    );
                                  })}
                                <div className="pagination">
                                  {item.contact.length <= 3 ? (
                                    ""
                                  ) : (
                                    <>
                                    {this.searchIndia(item.contact).length==0?<div>No results</div>:
                                      <Pagination
                                        count={Math.ceil(
                                          this.searchIndia(item.contact).length / 6
                                        )}
                                        onChange={(e, number, index) =>
                                          this.paginationItemNumer(
                                            e,
                                            number,
                                            index
                                          )
                                        }
                                      />
                                  }
                                    </>
                                  )}
                                </div>
                              </>
                            ) : (
                              <>
                                {this.searchNepal(item.contact)?.map((itemInfo, index) => {
                                  return (
                                    <div className="col-md-6 child" key={index}>
                                      <div className="emergency-block">
                                        <p className="emergency-title">
                                          {itemInfo.name}
                                        </p>
                                        <p className="emergency-number">
                                          {itemInfo.number}
                                        </p>
                                      </div>
                                    </div>
                                  );
                                })}
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-5">
                      <div className="emergency-side-info">
                        {item.countryName === "India" ? (
                          <div className="info-contact">
                            <div className="info-nummber">
                              <p className="content">Helpline Number</p>
                              <p className="content bold">+91-11-23978046</p>
                            </div>
                            <div className="info-nummber">
                              <p className="content">Toll free</p>
                              <p className="content bold">+91-11-23978046</p>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        <div className="emergency-links">
                          <p className="title bold">Important Links</p>
                          {item?.links?.map((linkinfo, index) => {
                            return (
                              <p className="content" key={index}>
                                {linkinfo.linksUrl}
                              </p>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </div>
    );
  };

  render() {
    return <>{this.emergencyContact()}</>;
  }
}

export default EmergencyContact;
