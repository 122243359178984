import "./App.css";
import Footer from "./component/Footer/Footer";
import Info from "./component/Info/Info";
import Navbar from "./component/Navbar/Navbar";
import Slider from "./component/slider/slider";

import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Home from "./component/Home/Home";
import GIFPage from "./component/Home/GIFPage/GIFPage";
import EmergencyContact from "./component/Home/EmergencyContacts/EmergencyContacts";
import OxygenDonor from "./component/Forms/oxygenDonor";
import OxygenPatient from "./component/Forms/oxygenpatient";
import PlasmaDonor from "./component/Forms/plasmaDonor";
import PlasmaPatient from "./component/Forms/plasmapatient";
import ReceiverDonorPage from "./component/Home/ReceiverDonor/ReceiverDonorPage";
import OxygenReceiverDonorPage from "./component/Home/OxygenReceiverDonorPage/OxygenReceiverDonorPage";
import React from "react";
import Datas from "./component/Home/datas";
import AOS from "aos";
import FoodSuppliers from "./component/Home/FoodSuppliers/FoodSuppliers";
import "aos/dist/aos.css";
import Header from "./component/Home/header.js";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

class App extends React.Component {
  state = {
    isVerified: false,
  };
  setPage = (page) => {
    this.setState({
      page: page,
    });
  };

  componentDidMount() {
    this.setState({
      ...this.state,
      countries: Datas,
    });
    AOS.init({ disable: "mobile" });
  }

  changeCountry(event) {
    this.setState({ selectedCountry: event.target.value });
    this.setState({
      districts: this.state.countries.find(
        (cntry) => cntry.name === event.target.value
      ).districts,
    });
  }
  render() {
    return (
      <div className="container">
        <ToastContainer
          position="top-right"
          hideProgressBar={false}
          autoClose={4000}
          newestOnTop={true}
          closeOnClick={false}
          draggable={false}
          rtl={false}
        />
        <Router>
          <Header />
          <Switch>
            <Route exact path="/oxygen/patient">
              <OxygenPatient
                selectedCountries={this.state.selectedCountries}
                districts={this.state.districts}
                changeCountry={(e) => this.changeCountry(e)}
              />
            </Route>
            <Route path="/oxygen/donor">
              <OxygenDonor
                selectedCountries={this.state.selectedCountries}
                districts={this.state.districts}
                changeCountry={(e) => this.changeCountry(e)}
              />
            </Route>
            <Route path="/blood/receiver">
              <PlasmaPatient
                selectedCountries={this.state.selectedCountries}
                districts={this.state.districts}
                changeCountry={(e) => this.changeCountry(e)}
              />
            </Route>
            <Route path="/blood/donor">
              <PlasmaDonor
                selectedCountries={this.state.selectedCountries}
                districts={this.state.districts}
                changeCountry={(e) => this.changeCountry(e)}
              />
            </Route>

            <Route path={`/`} exact component={Home} />
            <Route path={"/main"} exact component={GIFPage} />
            <Route
              path={"/emergency-contacts"}
              exact
              component={EmergencyContact}
            />
            <Route
              path={"/food-and-suppliers"}
              exact
              component={FoodSuppliers}
            />

            <Route path={"/blood"} exact component={ReceiverDonorPage} />
            <Route path={"/oxygen"} exact component={OxygenReceiverDonorPage} />
          </Switch>
        </Router>
      </div>
    );
  }
}

export default App;
