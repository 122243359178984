import react from "react";
import "./Footer.scss";

class Footer extends react.Component {
  render() {
    return (
      <div className=" section-gap">
        <div className="footer ">
          <div className="container">
            <div className="row">
              <div className="col-md-4 initial">
                <p className="white bold">Company Info</p>
              </div>
              <div className="col-md-4 initial">
                <p className="white bold">Contact Info</p>
                <p className="white">+977-9812345678</p>
                <p className="white">Kathmandu, Nepal</p>
              </div>
              <div className="col-md-4 initial">
                <p className="white bold">Quick Links</p>
                <p className="white">-Home</p>
                <p className="white">-Trek Packages</p>
                <p className="white">-Tour Packages</p>

                <p className="white">-Destinations</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Footer;
