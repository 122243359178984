import react from "react";
import "./ReceiverDonorPage.scss";
import { Link } from "react-router-dom";
import LoaderPage from "../../loader/LoaderPage.js";

class ReceiverDonorPage extends react.Component {
  render() {
    if(this.state= true){
      return (
        <>
          <div className="receiverDonorPage-page">
            <div className="donar-receiver-section">
              <div className="row justify-content-md-center">
                <div className="col-lg-8">
                  <div className="section-card">
                    <h2 className="title">Select an option</h2>
                    <div className="row">
                      <div className="col-lg-6 col-md-6  col-sm-6 pr-10">
                        <Link to="/blood/receiver" className="donar-receiver-image">
                          <img src="images/receiver.jpg" alt="img" />
                        </Link>
                      </div>
                      <div className="col-lg-6 col-md-6  col-sm-6 pl-10">
                        <Link to="/blood/donor" className="donar-receiver-image">
                          <img src={"images/donor.jpg"} alt="img" />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    }else{
      return(
        <>
          <LoaderPage />
        </>
      )
    }
  }
}

export default ReceiverDonorPage;
