import React from "react";
import "./Slider.scss";
import Slider from "react-slick";

import Modal from "react-awesome-modal";
import { Tabs, Tab } from "react-bootstrap";
import { Nav, Col, Row } from "react-bootstrap";
import Axios from "axios";
class Slide extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      see: false,
      country: [],
      districts: [],
    };
  }

  componentDidMount() {
    this.setState({
      country: [{ name: "Nepal" }, { name: "India" }],
    });
  }

  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    };
    return (
      <div className="slider">
        <div class="clippy"></div>
        <div class="row">
          <div class="col-md-7 col-xl-7">
            <div class="bg-col">
              <Slider {...settings}>
                <div>
                  <img src="images/clip.png" alt="Logo" />
                </div>
                <div>
                  <img src="images/ss.jpg" alt="Logo" />
                </div>
                <div>
                  <img src="images/index.jpeg" alt="Logo" />
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Slide;
