import react from "react";
import "./Home.scss";
import { Link } from "react-router-dom";
import FooterComponent from "../Home/footer.js";
import LoaderPage from "../loader/LoaderPage.js";
import Modal from "react-awesome-modal";

class Home extends react.Component {
  state = {
    visible: true,
  };

  openModal() {
    this.setState({
      visible: true,
    });
  }

  closeModal() {
    this.setState({
      visible: false,
    });
  }
  render() {
    return (
      <>
        <Modal
          visible={this.state.visible}
          width="500"
          height="fit-content"
          effect="fadeInDown"
          onClickAway={() => this.closeModal()}
        >
          <div className="modal-body-block">
            <div className="banner-img">
              <img src="images/renergo.jpg" alt="popup" />
            </div>
          </div>
        </Modal>
        <div className="home-page">
          <div className="homePage-content">
            <h1 className="main-title" data-aos="fade-up">
              {/* <RiDoubleQuotesL/>Small changes can make a Big Impact<RiDoubleQuotesR/> */}
              "Small changes can make a Big Impact"
            </h1>
            <p data-aos="fade-up" data-aos-duration="1000" className="content">
              As we are facing the multiple waves of Covid-19,
              <p className="p-content">
                many people are still struggling due to lack of blood and
                Oxygen.
              </p>
              <p>
                Letsavelife.com is here to help donor and the patient connent
                and fight this together.
              </p>
            </p>
            <div className="start-btn">
              <Link to="/main" className="btn btn-primary">
                Let's get Started
              </Link>
            </div>
          </div>
        </div>
        <FooterComponent />
      </>
    );
  }
}
export default Home;
