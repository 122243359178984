import React, { useState } from "react";
import axios from "axios";
import "./Form.scss";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useFormik } from 'formik';
import * as Yup from "yup";
import Datas from "../Home/datas";

// import Recaptcha from "react-recaptcha";

import { useHistory } from "react-router-dom";

function OxygenDonor({
  selectedCountries,
  districts,
  changeCountry,
}) {
  
  const [isverified, setIsverified] = useState(false);
  const [isLoading, setIsloading] = useState(false);
  const countries = Datas;


  const history = useHistory();

  const setCountries = (e) => {
    formik.setValues({
      ...formik.values,
      country:e.target.value
    })
    changeCountry(e);
  };

 

  const captchaClick = () => {
    setIsverified(true)
  }

  const nameRegex = /^[a-zA-Z ]*$/;

  const ValidationSchema = Yup.object().shape({
    country: Yup.string().required("Country is required"),
    name: Yup.string()
      .matches(nameRegex,"Should contain only alphabets")
      .min(0, "Too Short!")
      .max(50, "Too Long!")
      .required("Full Name is required"),
    email: Yup.string().email("Invalid email"),
    district: Yup.string()
      .required("State is required"),
    mobile: Yup.number()
      .min(1000000000, "too short")
      .max(13000000000, "too long")
      .required("Phone Number is required"),
    source: Yup.string().required("Select any one")
    
  })

  const formik = useFormik({
    initialValues: {
      country:'',
      source:'',
      name:'',
      email: '',
      mobile:'',
      district:'',
    },
    validationSchema:ValidationSchema,
    onSubmit: async(values) => {
      setIsloading(true)
      await axios
        .post("https://admin.letsavelife.com/api/oxygen/donor", {
          country: values.country,
          is_organization: values.source,
          name: values.name,
          contact: values.mobile,
          email: values.email,
          district: values.district,
        })
        .then((res) => {
          console.log("dsfkjh");
          if (res.status === 200) {
            toast.success("form submitted successfully", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
          history.push("/main");
          setIsloading(false)
        });

    // }
    // else{
    //   alert("you must be verified first")
    // }
  
    },
  });
 

      
      
  return (
    <div class="custom-form oxygen-donar">
      <ToastContainer
        position="top-left"
        hideProgressBar={false}
        autoClose={4000}
        newestOnTop={true}
        closeOnClick={false}
        draggable={false}
        rtl={false}
      />
      <form onSubmit={formik.handleSubmit}>

      <div class="tab-content">
        <div class="row justify-content-md-center">
          <div className="col-md-9">
            <div className="section-card">
              <div className="row">
                <div className="col-md-6 child">
                  <div className="input-content-block">
                    <label className="content" for="country">
                      Select your Country<i className="required">*</i>
                    </label>

                    <select
                      className="input-field"
                      name="country"
                      id="country"
                      value={selectedCountries}
                      onChange={(e) => setCountries(e)}
                    >
                      <option selected disabled value="" > -- Choose Country --</option>
                      {countries.map((x) => {
                        return <option>{x.name}</option>;
                      })}
                    </select>
                    {formik.errors.country ? <div style={{color:"red"}}><small>{formik.errors.country}</small></div> : null}

                  </div>
                </div>

                <div class="col-md-6 child">
                  <div className="input-content-block">
                    <label className="content">Full Name<i className="required">*</i></label>
                    <input
                      className="input-field"
                      type="text"
                      placeholder="Enter your Name"
                      {...formik.getFieldProps("name")}
                    />
                    {formik.errors.name ? <div style={{color:"red"}}><small>{formik.errors.name}</small></div> : null}
                  </div>
                </div>
                <div class="col-md-6 child">
                  <div className="input-content-block">
                    <label className="content">Phone No.<i className="required">*</i></label>
                    <input
                      className="input-field"
                      type="number"
                      minLength="7"
                      min="1000000"
                      placeholder="Enter your Phone number"
                      {...formik.getFieldProps("mobile")}

                      />
                    {formik.errors.mobile ? <div style={{color:"red"}}><small>{formik.errors.mobile}</small></div> : null}

                  </div>
                </div>

                <div class="col-md-6 child">
                  <div className="input-content-block">
                    <label className="content">Email</label>
                    <input
                      className="input-field"
                      type="email"
                      placeholder="Enter your Email address"
                      {...formik.getFieldProps("email")}

                      />
                    {formik.errors.email ? <div style={{color:"red"}}><small>{formik.errors.email}</small></div> : null}

                  </div>
                </div>
                <div className="col-md-6 child">
                  <div className="input-content-block">
                  <label className="content">Select Your State<i className="required">*</i></label>
                    <select
                      className=" input-field"
                      name="District"
                      id="District"
                      {...formik.getFieldProps("district")}

                      >
                      <option selected disabled value="">-- choose state --</option>
                      {districts?.map((d, index) => {
                        return <option key={index}>{d}</option>;
                      })}
                    </select>
                    {formik.errors.district ? <div style={{color:"red"}}><small>{formik.errors.district}</small></div> : null}

                  </div>
                </div>
                <div className="col-md-6 child">
                  <div className="input-content-block">
                    <label className="content">
                      Are you a Organization or Individual?<i className="required">*</i>
                    </label>

                    <div className="radio-block">
                      <div className="radio-content">
                        <label className="content" for="true">
                          <input
                            type="radio"
                            id="true"
                            name="source"
                            value="true"
                            onChange={() => formik.setValues({
                              ...formik.values,
                              source:"true"
                            })}
                            />
                          Organization
                        </label>
                      </div>
                      <div className="radio-content">
                        <label className="content" for="false">
                          <input
                            type="radio"
                            id="false"
                            name="source"
                            value="false"
                            onChange={() => formik.setValues({
                              ...formik.values,
                              source:"false"
                            })}
                            />
                          Individual
                        </label>
                      </div>
                      {formik.errors.source ? <div style={{color:"red"}}><small>{formik.errors.source}</small></div> : null}
                    </div>
                  </div>
                </div>
              </div>

              <div className=" condition">
                <label class="small-content">
                  By submitting this form,you accept terms and conditions
                </label>
              </div>
              <div className="center" style={{textAlign:"center"}}>
              {/* <Recaptcha
                sitekey="6LfUivQaAAAAALVG3yflLPxuh2Jn89Ks_zM5eIpN"
                render="explicit"
                verifyCallback={captchaClick}
              /> */}

              </div>
              <div class="text-center">
                {isLoading?
                <button class="btn btn-primary btn-add" disabled type="submit">
                  Submit
                </button>:
                <button class="btn btn-primary btn-add" type="submit">
                  Submit
                </button>
}
              </div>
            </div>
          </div>{" "}
        </div>
      </div>
              </form>
    </div>
  );
}

export default OxygenDonor;
