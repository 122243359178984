import React, { useState } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory } from "react-router-dom";
import Recaptcha from "react-recaptcha";
import * as Yup from "yup";
import { useFormik } from "formik";
import Datas from "../Home/datas";


function PlasmaDonor({
  selectedCountries,
  districts,
  changeCountry,
}) {
  
  const countries = Datas;
  const [isEligible, setIsEligible] = useState(true);
  const [isverified, setIsverified] = useState(false);
  const [isLoading, setIsloading] = useState(false);

  const history = useHistory();

  const captchaClick = () => {
    setIsverified(true);
  };

  const nameRegex = /^[a-zA-Z ]*$/;
  const ValidationSchema = Yup.object().shape({
    name: Yup.string()
      .matches(nameRegex,"Should contain only alphabets")
      .min(0, "Too Short!")
      .max(50, "Too Long!")
      .required("Full Name is Required"),
    email: Yup.string().email("Invalid email"),
    district: Yup.string()
      .required("State is required"),
    mobile: Yup.number()
      .min(1000000000, "too short")
      .max(13000000000, "too long")
      .required("Mobile number is required"),
    age: Yup.number().required("Age is required"),
    blood: Yup.string().required("Brood Group is required"),
    isCovid: Yup.string().required("Select any one"),
    country: Yup.string().required("Country is required"),
    // recoveryDate: Yup.number().required("required"),
    donatedBefore: Yup.string().required("Select any one"),
    // donationNumber: Yup.number().required("required"),
    gender: Yup.string().required("Select any one"),
    citizenship: Yup.string().required("Document Number is required"),
  })

  const formik = useFormik({
    initialValues: {
      country:'',
      age:'',
      name:'',
      email: '',
      mobile:'',
      district:'',
      blood:'',
      gender:'',
      donationNumber:'',
      recoveryDate:'',
      donatedBefore:'',
      isCovid:'',
      citizenship:'',
      iseligible:'',
    },
    validationSchema:ValidationSchema,

    onSubmit: async(values) => {
      console.log(isEligible)
      if(values.iseligible==="Yes"){
        setIsloading(true)
        await axios
        .post("https://admin.letsavelife.com/api/plasma/donor", {
          country: values.country,
          is_covidPatient: values.isCovid,
          name: values.name,
          contact: values.mobile,
          email: values.email,
          age: values.age,
          gender: values.gender,
          blood_type: values.blood,
          district: values.district,
          date_of_recovery: values.recoveryDate,
          donated_plasma: values.donatedBefore,
          no_of_times_donated: values.donationNumber,
          document_no: values.citizenship,
        })
        .then((res) => {
          console.log("done");
          if (res.status === 200) {
            toast.success("form submitted successfully", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            history.push("/main");
            setIsloading(false)
          }
        });

      }
      else{
        alert("Sorry you are not eligible to donate blood")
      }
    }})

    const setCountries = (e) => {
      formik.setValues({
        ...formik.values,
        country:e.target.value
      })
      changeCountry(e);
    };
  return (
    <div class="custom-form plasma-donar">
      <ToastContainer
        position="top-left"
        hideProgressBar={false}
        autoClose={4000}
        newestOnTop={true}
        closeOnClick={false}
        draggable={false}
        rtl={false}
      />
      <form onSubmit={formik.handleSubmit}>
      <div class="tab-content">
        <div class="row justify-content-md-center">
          <div className="col-lg-9 col-md-12">
            <div className="section-card">
              <div className="row">
                <div className="col-lg-6 col-md-6 child">
                  <div className="input-content-block">
                    <label className="content" for="country">
                      Select your Country<i className="required">*</i>
                    </label>

                    <select
                      className="input-field"
                      name="country"
                      id="country"
                      value={selectedCountries}
                      onChange={(e) => setCountries(e)}
                      // onChange={this.selectChange.apply.bind(this)}
                    >
                      <option selected disabled value=""> --Choose Country--</option>
                      {countries?.map((x) => {
                        return <option>{x.name}</option>;
                      })}
                    </select>
                  {formik.errors.country ? <div style={{color:"red"}}><small>{formik.errors.country}</small></div> : null}

                  </div>
                </div>
                <div className="col-lg-6 col-md-6 child">
                  <div className="input-content-block">
                    <label for="country" className="content">
                      Did you Ever get Covid <i className="required">*</i>
                    </label>
                    <select
                      className="input-field"
                      name="covid"
                      id="covid"
                      {...formik.getFieldProps("isCovid")}
                    >
                      <option selected disabled value="">-- Select --</option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                  {formik.errors.isCovid ? <div style={{color:"red"}}><small>{formik.errors.isCovid}</small></div> : null}
                  </div>

                </div>

                <div class="col-lg-6 col-md-6 child">
                  <div className="input-content-block">
                    <label className="content">Full Name<i className="required">*</i></label>
                    <input
                      className="input-field"
                      type="text"
                      {...formik.getFieldProps("name")}

                      placeholder="Enter your name"
                    />
                    {formik.errors.name ? <div style={{color:"red"}}><small>{formik.errors.name}</small></div> : null}

                  </div>
                </div>
                <div class="col-lg-6 col-md-6 child">
                  <div className="input-content-block">
                    <label className="content">Age<i className="required">*</i></label>
                    <input
                      className="input-field"
                      type="number"
                      min="0"
                      {...formik.getFieldProps("age")}

                      placeholder="Enter your Age"
                    />
                    {formik.errors.age ? <div style={{color:"red"}}><small>{formik.errors.age}</small></div> : null}

                  </div>
                </div>

                <div class="col-lg-6 col-md-6 child">
                  <div className="input-content-block">
                    <label className="content">Mobile no.<i className="required">*</i></label>
                    <input
                      className="input-field"
                      type="number"
                      minLength="7"
                      min="1000000"
                      {...formik.getFieldProps("mobile")}

                      placeholder="Enter your Mobile Number"
                    />
                    {formik.errors.mobile ? <div style={{color:"red"}}><small>{formik.errors.mobile}</small></div> : null}

                  </div>
                </div>
                <div class="col-lg-6 col-md-6 child">
                  <div className="input-content-block">
                    <label className="content">Email</label>
                    <input
                      className="input-field"
                      type="email"
                      {...formik.getFieldProps("email")}

                      placeholder="Enter your Email Address "
                    />
                    {formik.errors.email ? <div style={{color:"red"}}><small>{formik.errors.email}</small></div> : null}

                  </div>
                </div>

                <div className="col-lg-6 col-md-6 child">
                  <div className="input-content-block">
                    <label className="content"> Gender<i className="required">*</i></label>
                    <select
                      className="input-field"
                      name="gender"
                      id="gender"
                      {...formik.getFieldProps("gender")}

                      // onChange={this.selectChange.apply.bind(this)}
                    >
                      <option disabled selected value="">--Select Gender--</option>
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                      <option value="others">Other</option>
                    </select>
                    {formik.errors.gender ? <div style={{color:"red"}}><small>{formik.errors.gender}</small></div> : null}

                  </div>
                </div>

                <div class="col-lg-6 col-md-6 child">
                  <div className="input-content-block">
                    <label className="content" for="blood-grp">
                      Blood Group<i className="required">*</i>
                    </label>
                    <select
                      className="input-field"
                      name="blood-grp"
                      id="blood-grp"
                      {...formik.getFieldProps("blood")}
                    >
                      <option disabled selected value="">Please Select blood group</option>
                      <option value="A+">A+</option>
                      <option value="A-">A-</option>
                      <option value="B+">B+</option>
                      <option value="B-">B-</option>
                      <option value="O+">O+</option>
                      <option value="O-">O-</option>
                      <option value="AB+">AB+</option>
                      <option value="AB-">AB-</option>
                    </select>
                    {formik.errors.blood ? <div style={{color:"red"}}><small>{formik.errors.blood}</small></div> : null}

                  </div>
                </div>

                <div className="col-lg-6 col-md-6 child">
                  <div className="input-content-block">
                    <label className="content" for="district">
                      Select your State<i className="required">*</i>
                    </label>
                    <select
                      className="input-field"
                      name="District"
                      id="district"
                      {...formik.getFieldProps("district")}

                    >
                      <option selected disabled value="">-- Choose State --</option>
                      {districts?.map((d, index) => {
                        return <option key={index}>{d}</option>;
                      })}
                    </select>
                    {formik.errors.district ? <div style={{color:"red"}}><small>{formik.errors.district}</small></div> : null}

                  </div>
                </div>
                <div className="col-lg-6 col-md-6 child">
                  <div className="input-content-block">
                    <label className="content" for="blood-grp">
                      Citizenship or Adhar Card number?<i className="required">*</i>
                    </label>
                    <input
                      className="input-field "
                      type="number"
                      min="0"
                      {...formik.getFieldProps("citizenship")}

                      placeholder="Citizenship/Adhar card no"
                    />
                    {formik.errors.citizenship ? <div style={{color:"red"}}><small>{formik.errors.citizenship}</small></div> : null}

                  </div>
                </div>

                <div className="col-lg-6 col-md-6 child">
                  <div className="input-content-block">
                    <label className="content" for="blood-grp">
                      What was your date of recovery?
                    </label>
                    <input
                      className="input-field "
                      type="date"
                      {...formik.getFieldProps("recoveryDate")}

                    />
                    {/* {formik.errors.recoveryDate ? <div style={{color:"red"}}><small>{formik.errors.recoveryDate}</small></div> : null} */}

                  </div>
                </div>
                <div className="col-lg-6 col-md-6 child">
                  <div className="input-content-block">
                    <label className="content" for="blood-grp">
                      Have you donated blood before also?<i className="required">*</i>
                    </label>

                    <select
                      className="input-field"
                      name="covid"
                      id="covid"
                      {...formik.getFieldProps("donatedBefore")}

                    >
                      <option selected disabled value="">-- Select --</option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                  {formik.errors.donatedBefore ? <div style={{color:"red"}}><small>{formik.errors.donatedBefore}</small></div> : null}

                  </div>

                </div>

                <div className="col-lg-6 col-md-6 child">
                  <div className="input-content-block">
                    <label className="content" for="blood-grp">
                      Is latest covid negative test in last 2 weeks?
                    </label>
                    <select
                      className="input-field"
                      name="covid"
                      id="covid"
                      {...formik.getFieldProps("iseligible")}
                    >
                      <option selected disabled value="">-- Select --</option>
                      <option value="Yes">Yes</option>
                      <option value="No" >No</option>
                    </select>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 child">
                  <div className="input-content-block">
                    <label className="content" for="blood-grp">
                      Number of times you donated.
                    </label>
                    <input
                      className="input-field "
                      type="number"
                      min="0"
                      {...formik.getFieldProps("donationNumber")}

                      placeholder=""
                    />
                    {/* {formik.errors.donationNumber ? <div style={{color:"red"}}><small>{formik.errors.donatedNumber}</small></div> : null} */}

                  </div>
                </div>
              </div>

              <div>
                <label class="small-content ">
                  By submitting this form,you accept terms and conditions
                </label>
              </div>
              <div class="text-center">
              {isLoading?
                <button class="btn btn-primary btn-add" disabled type="submit">
                  Submit
                </button>:
                <button class="btn btn-primary btn-add" type="submit">
                  Submit
                </button>
}
              </div>
            </div>
          </div>
        </div>
      </div>
      </form>
    </div>
  );
}

export default PlasmaDonor;
