import react from "react";
import "./GIFPage.scss";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import LoaderPage from "../../loader/LoaderPage.js";

class GIFPage extends react.Component {
  render() {
    if ((this.state = true)) {
      return (
        <>
          <ToastContainer
            position="top-right"
            hideProgressBar={false}
            autoClose={4000}
            newestOnTop={true}
            closeOnClick={false}
            draggable={false}
            rtl={false}
          />
          <div className="gif-page">
            <div className="row justify-content-md-center gif-page-content">
              <div className="col-lg-4 col-md-4 col-sm-12 child">
                <Link to="/blood">
                  <div className="gif-block">
                    <div className="gif-image image-1">
                      <img src={"images/plasma_gif.gif"} alt="img" />
                      {/* <p className="content">Plasma</p> */}
                    </div>
                  </div>
                </Link>
                <Link to="/food-and-suppliers">
                  <div className="gif-block">
                    <div className="gif-image">
                      <img src={"images/food_gif.gif"} alt="img" />
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-12 child">
                <div className="second-col">
                  <div className="second-col-content" data-aos="fade-up">
                    {/* <h1 className="main-title" data-aos="fade-right">
                      COVID Donation
                    </h1> */}
                    <p className="content">
                      That gift which is given out of duty,
                      <p>at the proper time and place,</p>
                      <p>to a worthy person,</p>
                      <p>and without expectation of return,</p>
                      <p>is considered to be</p>
                      <p>charity in the mode of goodness.</p>
                    </p>
                    <p className="gita">-Bhagavad-Gita : 17.20</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-12 child">
                <Link to="/oxygen">
                  <div className="gif-block">
                    <div className="gif-image image-3">
                      <img src={"images/oxygen_gif.gif"} alt="img" />
                    </div>
                  </div>
                </Link>
                <Link to="/emergency-contacts">
                  <div className="gif-block">
                    <div className="gif-image">
                      <img src="images/Emergency-Contacts_gif.gif" alt="img" />{" "}
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </>
      );
    } else {
      return (
        <>
          <LoaderPage />
        </>
      );
    }
  }
}

export default GIFPage;
