
import React,{useState, useCallback} from "react";
import "./FoodSuppliers.scss";
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";

export default  function FoodSuppliers() {


            const videoUrl = [
                "pQuWU9AEDBg",

                "j1kRelMAfzw",

                "dQfjmaiVRqM",

                "3hTA8kIPnc4",

                "FLl8tvw_y4o",
            ]
            const photos = [
                {
                    src: "https://letsavelife.com/images/successstories/1.jpeg",
                    width: 4,
                    height: 3
                  },
                  {
                    src: "https://letsavelife.com/images/successstories/2.jpeg",
                    width: 3,
                    height: 3
                  },
                  {
                    src: "https://letsavelife.com/images/successstories/3.jpeg",
                    width: 3,
                    height: 4
                  },
                  {
                    src: "https://letsavelife.com/images/successstories/4.jpeg",
                    width: 3,
                    height: 3
                  },
                  {
                    src: "https://letsavelife.com/images/successstories/5.jpeg",
                    width: 4,
                    height: 3
                  },
                  {
                    src: "https://letsavelife.com/images/successstories/6.jpeg",
                    width: 4,
                    height: 4
                  },
                  {
                    src: "https://letsavelife.com/images/successstories/7.jpeg",
                    width: 4,
                    height: 4
                  },
                  {
                    src: "https://letsavelife.com/images/successstories/9.jpeg",
                    width: 5,
                    height: 4
                  },
                  {
                    src: "https://letsavelife.com/images/successstories/10.jpeg",
                    width: 4,
                    height: 3
                  },
                  {
                    src: "https://letsavelife.com/images/successstories/11.jpeg",
                    width: 2,
                    height: 3
                  },
                  {
                    src: "https://letsavelife.com/images/successstories/12.jpeg",
                    width: 3,
                    height: 4
                  },
                  {
                    src: "https://letsavelife.com/images/successstories/13.jpeg",
                    width: 3,
                    height: 3
                  }

            ]
            const [currentImage, setCurrentImage] = useState(0);
            const [viewerIsOpen, setViewerIsOpen] = useState(false);
                    
            const openLightbox = useCallback((event, { photo, index }) => {
              setCurrentImage(index);
              setViewerIsOpen(true);
            }, []);
        
            const closeLightbox = () => {
              setCurrentImage(0);
              setViewerIsOpen(false);
            };

            return (
                <>
                    <div className="food-supplies card">

                        <div className="row">
                            <div className="col-md-7">
                                <h3 className="mb-0">Donate and Help</h3>
                                <img src="https://letsavelife.com/images/header-underline.png" className="header-underline" />
                                <p class="mt-25 mb-2">We accept donations of all kinds of food, groceries and essentials items for daily livelihood. Your support will help put food on the table for vulnerable and disadvantaged families and meet their everyday needs. Motivating to move ahead with positivity and hope.</p>
                                <p>If you’re interested in learning more you can directly contact us and contribute with the best way possible.</p>


                            </div>
                            <div className="col-md-5">
                                <div className="bg-color-primary mb-0 ">
                                    <h5>Feel Free to contact us at:</h5><br></br>

                                    <p class="text">India </p>
                                    <div class="text-name">
                                        <p>Shubham:   <a href="tel:9899107543">+91 - 9899107543</a></p>
                                        <p>Sumit Joshi: <a href="tel:9634184251">+91 - 9634184251</a></p>
                                    </div>


                                    <p class="text">Nepal</p>
                                    <div>
                                        <p>Shubham: <a href="tel:9808372189">+977 - 9808372189</a></p>
                                        <p>Dhiraj Bahadur Pant:<a href="tel:9802940777">  +977 - 9802940777</a></p>

                                        <p>Sumit Joshi:  <a href="tel:9803267070">+977 - 9803267070</a></p>
                                    </div>

                                </div>

                            </div>

                        </div>

                    </div>
                       
                    <div class="gallery card">
                        <div className="text-center"><h3 className="title-gap">Our Success Stories</h3>
                        <img src="https://letsavelife.com/images/header-underline.png" className="header-underline" />
                        </div>
                        <div className="row">
                            <div className="col-md-12 p-gallery">
                            <Gallery photos={photos} onClick={openLightbox} direction={"column"}/>
                            <ModalGateway>
                              {viewerIsOpen ? (
                                <Modal onClose={closeLightbox}>
                                  <Carousel
                                    currentIndex={currentImage}
                                    views={photos.map(photo => ({
                                      ...photo,
                                      srcset: photo.srcSet,
                                      caption: photo.title
                                    }))}
                                  />
                                </Modal>
                              ) : null}
                            </ModalGateway>
                            </div>
                        </div>
                    </div>
                        
                    <div class="gallery card">
                        <div className="text-center"><h3 className="title-gap">Some Glimpse </h3><img src="https://letsavelife.com/images/header-underline.png" className="header-underline" /></div>
                        <div className="row p-video">
                            {videoUrl?.map((url,index)=>(
                                    <div className="col-md-4" style={{paddingBottom:"2%"}}>
                                    <iframe src={"https://www.youtube.com/embed/"+url}
                                            frameborder='0'
                                            allow='autoplay; encrypted-media'
                                            allowFullScreen
                                            title='video'
                                            height="100%"
                                            width="100%"
                                        />   
                                    </div>
                            ))}
                        </div>
                    </div>
                    <br />
                    <br />
                </>
            );
       
}


