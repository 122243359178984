import React from "react";
import "./Navbar.scss";
import "bootstrap/dist/css/bootstrap.min.css";

class Navbar extends React.Component {
  render() {
    return (
      <div className="">
        <div class="Navbar-content container ">
          <nav class="navbar navbar-expand-lg navbar-light ">
            <a class="navbar-brand" href="#"></a>
            <button
              class="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span class="navbar-toggler-icon"></span>
            </button>

            <div class="collapse navbar-collapse" id="navbarSupportedContent">
              <ul class="navbar-nav ml-auto">
                <li class="nav-item active">
                  <a class="nav-link" href="#home">
                    Home <span class="sr-only">(current)</span>
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#">
                    About Covid-19
                  </a>
                </li>

                <li class="nav-item">
                  <a class="nav-link " href="#">
                  Blood
                  </a>
                </li>

                <li class="nav-item">
                  <a class="nav-link " href="#">
                    Oxygen
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link " href="#">
                    FAQ
                  </a>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </div>
    );
  }
}

export default Navbar;
