import React from "react";
import { Link } from "react-router-dom";
import "./Home.scss";

function Header() {
  return (
    <div className="nav-bar-content">
      <div className="navbar-logo">
      <Link to="/">
          <img src="https://letsavelife.com/images/letsavelife_logo_150px.png" alt="img" />
        </Link>
      </div>
      <div className="nav-link-content">
              <Link to="/food-and-suppliers"  className="nav-link">
              Success Stories
              </Link>
            </div>
    </div>
  );
}
export default Header;